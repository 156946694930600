import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import ElementUI from 'element-ui';

/** 国际化 **/
Vue.use(VueI18n);
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': Object.assign(require('@/common/lang/zh-CN'), zhLocale),
    'en': Object.assign(require('@/common/lang/en'), enLocale),
    'ja': Object.assign(require('@/common/lang/ja'), enLocale),
    'ko': Object.assign(require('@/common/lang/ko'), enLocale),
    'de': Object.assign(require('@/common/lang/de'), enLocale),
    'fr': Object.assign(require('@/common/lang/fr'), enLocale),
    'it': Object.assign(require('@/common/lang/it'), enLocale),
    'ar': Object.assign(require('@/common/lang/ar'), enLocale),
    'hk': Object.assign(require('@/common/lang/zh-HK'), enLocale),
    'es': Object.assign(require('@/common/lang/es'), enLocale),
  }
});

export default i18n
